/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviDataGridModule */

import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import Fuse from 'fuse.js';
import { ClrDatagridStateInterface } from '@clr/angular';

import {
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

import {
  isEmpty,
  isUndefined,
  map,
} from 'lodash';

import {
  IAviDataGridConfig,
  IAviDataGridRow,
  IRowReorder,
} from './avi-data-grid.types';

/**
 * Options to be passed to Fuse constructor function.
 * Refer https://fusejs.io/api/options.html.
 */
const fuseOptions = {
  threshold: 0,
  ignoreLocation: true,
  keys: [] as string[],
};

/**
 * @description
 *     Data grid component which makes use of avi-data-grid-base component to support basic list
 *     data displaying.
 *
 *     When using custom templates for cells, we need to use templateRefs as creating a template
 *     from a string is not supported in Angular. This means that the parent component needs to
 *     create a templateRef variable, then pass it into config.fields in ngAfterViewInit. The outlet
 *     context contains the row and the index.
 *
 *     Please refer to avi-data-grid.stories.ts for an example.
 *
 * @author alextsg, Zhiqian Liu
 */
@Component({
  selector: 'avi-data-grid',
  templateUrl: './avi-data-grid.component.html',
  styleUrls: ['./avi-data-grid.component.less'],
})
export class AviDataGridComponent {
  /**
   * Grid configuration object containing getUniqueRowId, multipleactions, etc.
   */
  @Input()
  public config: IAviDataGridConfig;

  /**
   * Actual data to display in grid.
   */
  @Input()
  public rows: IAviDataGridRow[] = [];

  /**
   * Grid Title sent to the grid.
   */
  @Input()
  public gridTitle: string;

  /**
   * Indicate if grid has alerts.
   */
  @Input()
  public hasAlerts = false;

  /**
   * Indicate whether the row data is being loaded.
   */
  @Input()
  public isLoading = false;
  
  /**
   * Label for show columns header in data grid column selection menu.
   */
  @Input()
  public showColumnsHeader = 'Show Columns';

  /**
   * Label for select all button in data grid column.
   */
  @Input()
  public columnSelectAllButtonLabel = 'Select All';

  /**
   * Placeholder for empty list.
   */
  @Input()
  public emptyListPlaceholderMessage = 'We couldn’t find any objects!';

  /**
   * Label for items per page.
   */
  @Input()
  public itemsPerPageLabel = 'Items per page';

  /**
   * Label for total items count.
   */
  @Input()
  public totalLabel = 'Total';

  /**
   * Fires on row selection change.
   */
  @Output()
  public onSelectionChange = new EventEmitter<IAviDataGridRow[]>();

  /**
   * Fires on row reordering.
   */
  @Output()
  public onRowOrderChange = new EventEmitter<IRowReorder>();

  /**
   * Data sent to grid after search.
   */
  public searchResults: IAviDataGridRow[] = [];

  /**
   * Search string.
   */
  public searchValue: string;

  /**
   * Fuse instance.
   */
  private fuse: Fuse<IAviDataGridRow>;

  /**
   * Latest data grid state object containing info about pagination, sorting and filtering.
   */
  private dataGridState: ClrDatagridStateInterface;

  /**
   * Partial of the total data to be displayed in the grid for one page.
   */
  public get displayedRows(): IAviDataGridRow[] {
    if (!this.rows || isUndefined(this.dataGridState)) {
      return [];
    }

    if (isEmpty(this.dataGridState)) {
      return this.searchValue ? this.searchResults : this.rows;
    }

    const { page = { size: 0, current: 0 } } = this.dataGridState;
    const { size = 0, current = 0 } = page;

    return this.searchValue ?
      this.searchResults.slice(size * (current - 1), size * current) :
      this.rows.slice(size * (current - 1), size * current);
  }

  /**
   * Method to handle search callback.
   */
  public handleSearch(searchValue: string): void {
    this.searchValue = searchValue;
    if(this.config.searchKeys) {
      fuseOptions.keys = this.config.searchKeys;
    }
    this.fuse = new Fuse(this.rows, fuseOptions);
    this.searchResults = map(this.fuse.search(searchValue || ''), 'item');
  }

  /**
   * Called on row selection change.
   * Help as a relay to bubble up the onSelectionChange output from avi-data-grid-base to the
   * parent using this avi-data-grid component.
   */
  public onRowSelectionChange(rows: IAviDataGridRow[]): void {
    this.onSelectionChange.emit(rows);
  }

  /**
   * Update the local data grid state with the latest one bubbled up from avi-data-grid-base.
   */
  public updateLocalDataGridState(state: ClrDatagridStateInterface): void {
    this.dataGridState = state;
  }

  /**
   * Changes indexes of Items and emits row change event to parent.
   */
  public handleRowOrderChange(event: CdkDragDrop<IAviDataGridRow[]>): void {
    const { previousIndex, currentIndex } = event;

    // If onRowOrderChange has observers,
    // Index change should be performed by onRowOrderChange callback.
    // else reorder the grid here itself.
    if (!this.onRowOrderChange.observers.length) {
      moveItemInArray(this.rows, previousIndex, currentIndex);
    } else {
      this.onRowOrderChange.emit({
        row: this.rows[previousIndex],
        currentIndex,
        previousIndex,
      });
    }
  }
}
