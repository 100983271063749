<div class="avi-data-grid">
  <h6
    *ngIf="gridTitle"
    class="avi-data-grid__grid-title"
  >
    {{ gridTitle }}
  </h6>
  <ng-content select="avi-data-grid_header"></ng-content>
  <div
    *ngIf="hasAlerts"
    class="avi-data-grid__grid-alerts"
  >
    <ng-content select="avi-data-grid_alerts"></ng-content>
  </div>
  <avi-data-grid-base
    *ngIf="config"
    [config]="config"
    [rows]="displayedRows"
    [rowsTotal]="rows.length"
    [isLoading]="isLoading"
    (onSelectionChange)="onRowSelectionChange($event)"
    (onDataGridStateChange)="updateLocalDataGridState($event)"
    (onRowOrderChange)="handleRowOrderChange($event)"
    (onSearch)="handleSearch($event)"
    [showSearch]="!!config.searchKeys?.length && !config.layout?.hideSearch"
    [showColumnsHeader]="showColumnsHeader"
    [columnSelectAllButtonLabel]="columnSelectAllButtonLabel"
    [emptyListPlaceholderMessage]="emptyListPlaceholderMessage"
    [itemsPerPageLabel]="itemsPerPageLabel"
    [totalLabel]="totalLabel"
  >
    <ng-container ngProjectAs="avi-data-grid_actions">
      <ng-content select="avi-data-grid_actions"></ng-content>
    </ng-container>
  </avi-data-grid-base>
</div>
