/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviUsageBarModule */

import {
  Input,
  Component,
  ViewChild,
} from '@angular/core';
import { AviUsageBarWithOverflowComponent } from './avi-usage-bar-with-overflow/avi-usage-bar-with-overflow.component';

import * as l10n from '../avi-usage-bar.l10n';

const { AviUsageBarResourceBundles: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *    Usage bar to display the consumption of usage resources. 
 *    Contains reserve tick to indicate reserved usage data.
 *    Contains overflow tick to indicate overflow if used resources exceed the maximum number of allowed resources.
 * @author Nikhil Sharma
 */
@Component({
  selector: 'avi-usage-bar',
  templateUrl: './avi-usage-bar.component.html',
  styleUrls: ['./avi-usage-bar.component.less'],
})
export class AviUsageBarComponent {
  public readonly l10nKeys = l10nKeys;
  public readonly dictionary = dictionary;

  /**
   * Show/hide header in the usage bar.
   */
  @Input()
  public isHeaderRequired? = false;

  /**
   * Show/hide footer in the usage bar.
   */
  @Input()
  public isFooterRequired? = false;

  /**
   * Number of usage bar units used.
   */
  @Input()
  public usedValue: number;

  /**
   * Set max allowed value to positive infinity, when it is 0.
   */
  @Input('maxAllowedValue')
  private set setMaxAllowedValue(maxAllowedValue: number) {
    this.maxAllowedValueText = maxAllowedValue + '';
    this.maxAllowedValue = maxAllowedValue === 0 ? Number.POSITIVE_INFINITY : maxAllowedValue;
  }
 
  /**
   * Maximum allowed license units.
   */
  public maxAllowedValue = 0;

  /**
   * Maximum allowed license units as shown to the user.
   */
  public maxAllowedValueText = '';

  /**
   * Number of reserved units in the usage bar.
   */
  @Input()
  public reservedValue? = 0;

  /**
   * Label for the number of usage bar units used.
   */
  @Input()
  public usedUnitsLabel? = dictionary[l10nKeys.usedLabel];

  /**
   * Label for the maximum number of units allowed in the usage bar.
   */
  @Input()
  public maxAllowedUnitsLabel? = dictionary[l10nKeys.maxLabel];

  /**
   * Label for the number of reserved units in the usage bar.
   */
  @Input()
  public reservedUnitsLabel? = dictionary[l10nKeys.reservedLabel];

  /**
   * This allows us to call methods or access properties of the child component.
   */
  @ViewChild(AviUsageBarWithOverflowComponent) 
  private readonly aviUsageBarWithOverFlowComponent: AviUsageBarWithOverflowComponent;

  /**
   * Call the overConsumedUnits method of avi-usage-bar-with-overflow component to get
   * the count of overconsumption of units.
   */
  public get overConsumedUnits(): number {
    return this.aviUsageBarWithOverFlowComponent?.overConsumedUnits;
  }
}
