<clr-modal
  [clrModalSize]="size"
  [clrModalOpen]="true"
  (clrModalOpenChange)="onDialogStatusChange($event)"
  [clrModalStaticBackdrop]="!closeOnBackdropClick"
  [clrModalClosable]="closeOnEscapeKeypress && allowDialogDismissal"
  class="avi-confirmation-dialog sel-avi-confirmation-dialog"
>
  <h3 class="modal-title">{{ headerText }}</h3>

  <div class="modal-body">
    <ng-content select="avi-confirmation-dialog_modal-content"></ng-content>
  </div>

  <div class="modal-footer">
    <ng-content select="avi-confirmation-dialog_modal-actions"></ng-content>
  </div>
</clr-modal>
