/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviDialogModule
 */

import {
  Component,
  Injectable,
  Type,
} from '@angular/core';
import { ObservableList } from '../../avi-utils/avi-observable-list.utils';

export interface IAviDialogProps {
  id: string;
  component: Type<Component>;
  componentProps?: Record<string, unknown>;
}

// Token used when registering as a downgraded service in AJS.
export const DIALOG_SERVICE_TOKEN = 'dialogService';

/**
* Service for adding and removing dialogs.
* @author alextsg
*/
@Injectable({
  providedIn: 'root',
})
export class AviDialogService extends ObservableList<IAviDialogProps, string> {
  protected getID(item: IAviDialogProps): string {
    return item.id;
  }
}
