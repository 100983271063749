/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { NgModule } from '@angular/core';
import { AviBackendErrorMsgPipe } from './avi-backend-error-msg.pipe';
import { AviL10nMsgPipe } from './avi-l10n.pipe';

@NgModule({
  declarations: [
    AviBackendErrorMsgPipe,
    AviL10nMsgPipe,
  ],
  exports: [
    AviBackendErrorMsgPipe,
    AviL10nMsgPipe,
  ],
  providers: [
    AviBackendErrorMsgPipe,
    AviL10nMsgPipe,
  ]
})
export class AviPipesModule {}
