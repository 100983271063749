/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'avi-card',
  templateUrl: './avi-card.component.html',
  styleUrls: [
    './avi-card.component.less',
  ],
})
export class AviCardComponent implements AfterViewInit {
  /**
   * If true, loader will be displayed.
   */
  @Input()
  public loading = false;

  /**
   * Indicate whether to add a body padding by default.
   */
  @Input()
  public noBodyPadding = false;

  /**
   * If true, the height of the card is dependent on the content instead of the parent's height.
   * Used, for example, in dashboard widgets where the heights of widgets aren't uniform and
   * shouldn't be based on the parent container's height.
   */
  @Input()
  public useContentHeight = false;

  /**
   * Reference to the div containing an optional transcluded avi-card_footer element.
   */
  @ViewChild('cardFooterContainerContent')
  private cardFooterContainer: ElementRef;

  /**
   * Reference to the div containing an optional transcluded avi-card_header element.
   */
  @ViewChild('cardHeaderContainerContent')
  private cardHeaderContainerContent: ElementRef;

  public hasTranscludedCardHeader = false;

  /**
   * Returns true if the avi-card_footer transcluded element exists.
   */
  public get hasTranscludedCardFooterContainer(): boolean {
    return this.cardFooterContainer?.nativeElement.children.length > 0;
  }

  /** @override */
  public ngAfterViewInit(): void {
    this.hasTranscludedCardHeader = this.cardHeaderContainerContent?.nativeElement.children.length > 0;
  }
}
