/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviDashboardModule
 */

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

/**
  * @description
  * Progress bar component for a dashboard widget. If loading is true, then we do calculations to
  * increment the progress bar percentage, since with HTTP requests we don't know the actual
  * progress.
  *
  * When loading is toggled from false to true, then the percentage is reset to 0 and starts
  * incrementing from there.
  * @author alextsg
  */
@Component({
  selector: 'avi-dashboard-widget-progress-bar',
  templateUrl: './avi-dashboard-widget-progress-bar.component.html',
})
export class AviDashboardWidgetProgressBarComponent implements OnChanges, OnDestroy {
  /**
   * True to start incrementing the progress bar.
   */
  @Input()
  public loading = false;

  /**
   * Percentage number passed to the clarity progress bar component.
   */
  public progressPercentage = 0;

  /**
   * Keep track of the setInterval ID to clear it when needed.
   */
  private intervalId: number | undefined;

  /**
   * @override
   * Check if the loading binding has toggled from false to true. If so, reset the percentage to
   * 0 and start incrementing again.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const { loading } = changes;

    if (loading) {
      const { currentValue, previousValue } = loading;

      if (currentValue !== previousValue) {
        if (currentValue) {
          this.progressPercentage = 0;
          this.incrementProgressPercentage();
        } else {
          clearInterval(this.intervalId);
          this.intervalId = undefined;
        }
      }
    }
  }

  /**
   * @override
   * Clear the setInterval ID when the component is destroyed.
   */
  public ngOnDestroy(): void {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
    }
  }

  /**
   * Increment the progress percentage. We take the remaining percentage, divide it by 2, and
   * multiply it by Math.random() to get a randomized value to increase the current percentage by.
   * The max number we want to hit is 99, so if the percentage hits 99 then we can stop
   * incrementing.
   */
  private incrementProgressPercentage(): void {
    this.intervalId = window.setInterval(() => {
      const remainingPercentage = 100 - this.progressPercentage;
      const increase = Math.random() * remainingPercentage / 2;

      this.progressPercentage = Math.min(this.progressPercentage + increase, 99);

      if (this.progressPercentage === 99) {
        clearInterval(this.intervalId);
      }
    }, 2000 * Math.random());
  }
}
