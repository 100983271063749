/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviDataGridModule */

import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ConnectedPosition } from '@angular/cdk/overlay';

import {
  Observable,
  Subject,
} from 'rxjs';

import {
  AviDataGridFieldVisibility,
  IAviDataGridConfigField,
  TAviDataGridColumnPresenceMap,
} from '../../avi-data-grid.types';

import { TOP_LEFT_ALIGNED_CONNECTED_POSITION } from '../../avi-data-grid.constants';

/**
 * @description Component for the pop-up column selection panel for the avi-data-grid family.
 * @author Zhiqian Liu
 */
@Component({
  selector: 'avi-data-grid-column-selection',
  templateUrl: './avi-data-grid-column-selection.component.html',
  styleUrls: ['./avi-data-grid-column-selection.component.less'],
})
export class AviDataGridColumnSelectionComponent {
  /**
   * Fields to hide/show.
   */
  @Input()
  public fields: IAviDataGridConfigField[];

  /**
   * Map to decide which field columns to show and which to hide.
   */
  @Input()
  public showColumnHash: TAviDataGridColumnPresenceMap = {};

  /**
   * Label for show columns header in data grid column selection menu.
   */
  @Input()
  public showColumnsHeader = 'Show Columns';

  /**
   * Label for select all button in data grid column.
   */
  @Input()
  public columnSelectAllButtonLabel = 'Select All';

  /**
   * Handles field columns value change of showColumnHash.
   */
  @Output()
  public showColumnHashChange = new EventEmitter<TAviDataGridColumnPresenceMap>();

  /**
   * Positions of the column selection panel relative to the hide/show button. Contains only one
   * entry of the primary postion.
   */
  public viewColumnPanelPositions: [ConnectedPosition] = [TOP_LEFT_ALIGNED_CONNECTED_POSITION];

  /**
   * Subject to control the hide/show column selection panel close button.
   */
  private columnSelectionCloseControlSubject = new Subject<boolean>();

  /**
   * Indicate whether all field columns are present.
   */
  public get allFieldColumnsShown(): boolean {
    return Object.values(this.showColumnHash).every(shown => shown === true);
  }

  /**
   * Return an observable to allow hide/show column panel to close itself through subscription.
   */
  public get columnSelectionCloseButtonControl$(): Observable<boolean> {
    return this.columnSelectionCloseControlSubject.asObservable();
  }

  /**
   * Check if a field is mandatory and can't be hidden.
   */
  public isMandatoryField(field: IAviDataGridConfigField): boolean {
    return field.visibility === AviDataGridFieldVisibility.MANDATORY;
  }

  /**
   * Show all field columns by resetting the column presence hash entries to all true.
   */
  public showAllFieldColumns(): void {
    Object.keys(this.showColumnHash)
      .forEach(id => this.showColumnHash[id] = true);
  }

  /**
   * Close the column hide/show panel.
   */
  public closeColumnSelectionPanel(): void {
    this.columnSelectionCloseControlSubject.next(false);
  }
}
