/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviDialogModule
 */

import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import * as l10n from '../../avi-dialog.l10n';

const { AviDialogResourceBundles, ...l10nKeys } = l10n;

/**
* @description Component to be used to make sure user wants to continue with intended action.
* @author Akul Aggarwal
*/
@Component({
  selector: 'avi-continue-confirmation-dialog',
  templateUrl: './avi-continue-confirmation-dialog.component.html',
})
export class AviContinueConfirmationDialogComponent {
  /**
   * Custom warning to be added if desired. Separate from continue question warning.
   * Should be passed in as translated l10n message.
   */
  @Input()
  public warning?: string;

  /**
   * Custom header to be added if desired.
   * Should be passed in as translated l10n message.
   */
  @Input()
  public customHeader?: string;

  /**
   * Event to be fired upon user canceling.
   */
  @Output()
  private readonly onClose = new EventEmitter();

  /**
   * Event to be fired upon user confirming continue.
   */
  @Output()
  private readonly onConfirm = new EventEmitter();

  public readonly l10nKeys = l10nKeys;

  public readonly AviDialogResourceBundles = AviDialogResourceBundles;

  /**
   * Header to display for popup.
   */
  public get header(): string {
    return this.customHeader || AviDialogResourceBundles[l10nKeys.warningHeader];
  }

  /**
   * Handles user clicking -> Cancel.
   */
  public handleCancel(): void {
    this.onClose.emit();
  }

  /**
   * Handles user clicking -> Yes, Continue.
   */
  public handleContinue(): void {
    this.onConfirm.emit();
  }
}
