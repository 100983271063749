/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviDialogModule
 */

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import * as l10n from '../../avi-dialog.l10n';

const { AviDialogResourceBundles, ...l10nKeys } = l10n;

export const enum AviDismissChangesWarningType {
  RESET_DEFAULTS,
  UNSAVED_CHANGES,
}

/**
* @description Component to be used when user takes an action which would dismiss/reset settings.
* @author Akul Aggarwal
*/
@Component({
  selector: 'avi-dismiss-changes-confirmation-dialog',
  templateUrl: './avi-dismiss-changes-confirmation-dialog.component.html',
})
export class AviDismissChangesConfirmationDialogComponent implements OnInit {
  /**
   * User of this component must decide warning type to use.
   */
  @Input()
  private readonly warningType: AviDismissChangesWarningType;

  /**
   * Event to be fired upon user Canceling.
   */
  @Output()
  private readonly onClose = new EventEmitter();

  /**
   * Event to be fired upon user Confirming continue.
   */
  @Output()
  private readonly onConfirm = new EventEmitter();

  /**
   * Warning to show to user.
   */
  public warning: string;

  /**
   * Header to display for popup.
   */
  public header: string;

  public readonly l10nKeys = l10nKeys;

  /**
   * Handles user clicking -> Cancel.
   */
  public handleCancel(): void {
    this.onClose.emit();
  }

  /**
   * Handles user clicking -> Yes, Continue.
   */
  public handleContinue(): void {
    this.onConfirm.emit();
  }

  /** @override */
  public ngOnInit(): void {
    this.setWarningAndHeader();
  }

  /**
   * This component's user decides which type of warning to show, this method handles decision.
   */
  private setWarningAndHeader(): void {
    switch (this.warningType) {
      case AviDismissChangesWarningType.RESET_DEFAULTS:
        this.header = AviDialogResourceBundles[l10nKeys.resetToDefaultsHeader];
        this.warning = AviDialogResourceBundles[l10nKeys.resetToDefaultsWarning];
        break;

      case AviDismissChangesWarningType.UNSAVED_CHANGES:
        this.header = AviDialogResourceBundles[l10nKeys.unsavedChangesHeader];
        this.warning = AviDialogResourceBundles[l10nKeys.unsavedChangesWarning];
    }
  }
}
