/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing tooltip directives and components.
 * @preferred
 * @module AviTooltipModule
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { AviTooltipDirective } from './directives';
import { AviLabelWithTooltipComponent } from './components';

@NgModule({
  declarations: [
    AviTooltipDirective,
    AviLabelWithTooltipComponent,
  ],
  exports: [
    AviTooltipDirective,
    AviLabelWithTooltipComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
  ],
})
export class AviTooltipModule { }
