/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module AviDashboardModule
 */

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { ClrProgressBarModule } from '@clr/angular';

import {
  AviDashboardWidgetProgressBarComponent,
  AviDashboardWidgetComponent,
} from './avi-dashboard-widget';

import { AviDashboardWidgetsComponent } from './avi-dashboard-widgets';
import { AviCardModule } from '../avi-card';

@NgModule({
  declarations: [
    AviDashboardWidgetProgressBarComponent,
    AviDashboardWidgetsComponent,
    AviDashboardWidgetComponent,
  ],
  imports: [
    ClrProgressBarModule,
    CommonModule,
    AviCardModule,
  ],
  exports: [
    AviDashboardWidgetsComponent,
    AviDashboardWidgetComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AviDashboardModule {}
