<div class="avi-usage-bar-with-overflow">
  <clr-progress-bar
    class="top avi-usage-bar-with-overflow__clr-usage-bar"
    clrValue="{{ overConsumedUnits ? maxAllowedValue : usedValue }}"
    clrMax="{{ overConsumedUnits ? usedValue : maxAllowedValue }}"
    [ngClass]="overConsumedUnits ? 'avi-usage-bar-with-overflow__clr-usage-bar--overflow' : 'avi-usage-bar-with-overflow__clr-usage-bar--default'"
  ></clr-progress-bar>
  <div>
    <div
      *ngIf="reservedValue"
      [style.left]="leftTickPosition"
      class="avi-usage-bar-with-overflow__range-tick avi-usage-bar-with-overflow__range-tick--left"
    ></div>
    <div 
      *ngIf="overConsumedUnits"
      [style.right]="rightTickPosition"
      class=" avi-usage-bar-with-overflow__range-tick avi-usage-bar-with-overflow__range-tick--right"
    ></div>
  </div>
</div>
