/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviTooltipModule */

import { ConnectedPosition } from '@angular/cdk/overlay';

export const enum TooltipPosition {
  BOTTOM_POSITION = 'BOTTOM_POSITION',
  BOTTOM_RIGHT_POSITION = 'BOTTOM_RIGHT_POSITION',
  BOTTOM_LEFT_POSITION = 'BOTTOM_LEFT_POSITION',
  TOP_POSITION = 'TOP_POSITION',
  TOP_RIGHT_POSITION = 'TOP_RIGHT_POSITION',
  TOP_LEFT_POSITION = 'TOP_LEFT_POSITION',
  LEFT_POSITION = 'LEFT_POSITION',
  RIGHT_POSITION = 'RIGHT_POSITION',
}

const CENTER = 'center';
const TOP = 'top';
const BOTTOM = 'bottom';
const START = 'start';
const END = 'end';

export const TOP_CONNECTED_POSITION: ConnectedPosition = {
  offsetY: -10,
  originX: CENTER,
  originY: TOP,
  overlayX: CENTER,
  overlayY: BOTTOM,
};

export const TOP_RIGHT_CONNECTED_POSITION: ConnectedPosition = {
  offsetX: -10,
  offsetY: -10,
  originX: END,
  originY: TOP,
  overlayX: START,
  overlayY: BOTTOM,
};

export const BOTTOM_RIGHT_CONNECTED_POSITION: ConnectedPosition = {
  offsetX: -10,
  offsetY: 10,
  originX: END,
  originY: BOTTOM,
  overlayX: START,
  overlayY: TOP,
};

export const TOP_LEFT_CONNECTED_POSITION: ConnectedPosition = {
  offsetX: 10,
  offsetY: -10,
  originX: START,
  originY: TOP,
  overlayX: END,
  overlayY: BOTTOM,
};

export const BOTTOM_LEFT_CONNECTED_POSITION: ConnectedPosition = {
  offsetX: 10,
  offsetY: 10,
  originX: START,
  originY: BOTTOM,
  overlayX: END,
  overlayY: TOP,
};

export const BOTTOM_CONNECTED_POSITION: ConnectedPosition = {
  offsetY: 10,
  originX: CENTER,
  originY: BOTTOM,
  overlayX: CENTER,
  overlayY: TOP,
};

export const defaultPositionsPriority = [
  TOP_CONNECTED_POSITION,
  TOP_RIGHT_CONNECTED_POSITION,
  BOTTOM_RIGHT_CONNECTED_POSITION,
  TOP_LEFT_CONNECTED_POSITION,
  BOTTOM_LEFT_CONNECTED_POSITION,
  BOTTOM_CONNECTED_POSITION,
];

export const positionMap = new Map([
  [BOTTOM_CONNECTED_POSITION, TooltipPosition.BOTTOM_POSITION],
  [BOTTOM_LEFT_CONNECTED_POSITION, TooltipPosition.BOTTOM_LEFT_POSITION],
  [BOTTOM_RIGHT_CONNECTED_POSITION, TooltipPosition.BOTTOM_RIGHT_POSITION],
  [TOP_CONNECTED_POSITION, TooltipPosition.TOP_POSITION],
  [TOP_LEFT_CONNECTED_POSITION, TooltipPosition.TOP_LEFT_POSITION],
  [TOP_RIGHT_CONNECTED_POSITION, TooltipPosition.TOP_RIGHT_POSITION],
]);
