<div
  class="avi-card"
  [class.avi-card--full-height]="!useContentHeight"
>
  <div class="avi-card__card card">
    <div
      [hidden]="!hasTranscludedCardHeader"
      class="card-header"
      #cardHeaderContainerContent
    >
      <ng-content select="avi-card_header"></ng-content>
    </div>
    <div
      class="avi-card__body card-block"
      [ngClass]="{'avi-card__body--no-padding': noBodyPadding}"
    >
      <ng-content
          *ngIf="!loading; else showLoaderTemplate"
          select="avi-card_body"
      ></ng-content>
    </div>
    <div
      [hidden]="!hasTranscludedCardFooterContainer"
      #cardFooterContainerContent
      class="card-footer"
    >
      <ng-content select="avi-card_footer"></ng-content>
    </div>
  </div>
</div>

<ng-template #showLoaderTemplate>
  <div class="avi-card__loader">
    <clr-spinner [clrMedium]="true"></clr-spinner>
  </div>
</ng-template>
