/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviTooltipModule */

import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: '[aviLabelWithTooltip]',
  templateUrl: './avi-label-with-tooltip.component.html',
  styleUrls: ['./avi-label-with-tooltip.component.less'],
})
export class AviLabelWithTooltipComponent {
  /**
   * @param tooltipText - Custom text unrelated to Schema to be shown.
   */
  @Input()
  public tooltipText = '';

  /**
   * @param objectType - The Schema object type that the field belongs to.
   */
  @Input()
  public objectType = '';

  /**
   * @param fieldName - The Schema field that contains the description text.
   */
  @Input()
  public fieldName = '';

  /**
   * @param hideRange - If true, hides the range text.
   */
  @Input()
  public hideRange = false;

  /**
   * @param enumType - The Schema enum type that the enum value belongs to.
   */
  @Input()
  public enumType = '';

  /**
   * @param enumValue - The Schema enum value that maps to contains the description text.
   */
  @Input()
  public enumValue = '';

  /**
   * @param for - Label "for" attribute.
   */
  @Input()
  public for = '';

  /**
   * If objectType is not available, we use range passed for showing value of range text.
   */
  @Input()
  public rangeText = '';

  /**
   * @param required - If the required binding exists or is equal to true, set this.required to
   *     true.
   */
  @Input('required')
  private set setRequired(required: boolean | '') {
    this.required = required === '' || required;
  }

  public required = false;
}
