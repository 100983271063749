/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviUsageBarModule */

import {
  Input,
  Component,
  SimpleChanges,
} from '@angular/core';

/**
 * @description
 *    Usage bar to display the consumption of usage resources. 
 *    Contains reserve tick to indicate reserved usage data.
 *    Contains overflow tick to indicate overflow if used resources exceed the maximum number of allowed resources.
 * @author Nikhil Sharma
 */
@Component({
  selector: 'avi-usage-bar-with-overflow',
  templateUrl: './avi-usage-bar-with-overflow.component.html',
  styleUrls: ['./avi-usage-bar-with-overflow.component.less'],
})

export class AviUsageBarWithOverflowComponent {
  /**
   * Number of usage bar units used.
   */
  @Input()
  public usedValue: number;

  /**
   * Maximum number of units allowed in the usage bar.
   */
  @Input()
  public maxAllowedValue: number;

  /**
   * Number of units kept reserved in the usage bar.
   */
  @Input()
  public reservedValue ?= 0;

  /**
   * Number of units overconsumed in the usage bar.
   */
  public overConsumedUnits: number;

  /**
   * Position of right tick from start of usage bar.
   */
  public rightTickPosition: string;

  /**
   * Position of left tick from start of usage bar.
   */
  public leftTickPosition: string;

  /**
   * Used to detect changes to the resources of the usage bar.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.updateOverConsumedUnits();
    this.updateLeftTickPosition();
    this.updateRightTickPosition();
  }

  /**
   * Calculate the position of left tick from start of usage bar.
   */
  private updateLeftTickPosition(): void {
    if(this.reservedValue){
      const maxUsageValue =  this.overConsumedUnits ? this.usedValue : this.maxAllowedValue;
      const leftTickRangePercentage = 100 * this.reservedValue / maxUsageValue;
      
      this.leftTickPosition = `${leftTickRangePercentage}%`;
    }
  }

  /**
   * Calculate the position of right tick from start of usage bar.
   */
  private updateRightTickPosition(): void {
    const rightTickRangePercentage = 100 * this.overConsumedUnits /
        (this.overConsumedUnits + this.maxAllowedValue);

    this.rightTickPosition = `${rightTickRangePercentage}%`;
  }

  /**
   * Get number of overconsumption units.
   */
  private updateOverConsumedUnits(): void {
    this.overConsumedUnits = this.usedValue > this.maxAllowedValue ? this.usedValue - this.maxAllowedValue : 0;
  }
}
