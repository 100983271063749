/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviPipesModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'underscore';

/**
 * Interface for backend API error.
 */
interface IAviError {
  error?: IAviError | string,
  Error?: IAviError | string,
  __all__?: IAviError | string,
  detail?: IAviError | string,
}

@Pipe({ name: 'aviBackendErrorMsg' })
export class AviBackendErrorMsgPipe implements PipeTransform {
  /**
   * Pipe that formats backend error messages into a string.
   */
  public transform(...args: Array<IAviError | string>): string {
    return AviBackendErrorMsgPipe.aviBackendErrorMsg(...args);
  }

  public static aviBackendErrorMsg(error?: IAviError | string): string {
    let res = '';

    if (error) {
      switch (typeof error) {
        case 'string':
          res = error;
          break;

        case 'object': {
          if (!isEmpty(error)) {
            try {
              if ('error' in error) {
                return AviBackendErrorMsgPipe.aviBackendErrorMsg(error.error);
              }

              if ('Error' in error) {
                return AviBackendErrorMsgPipe.aviBackendErrorMsg(error.Error);
              }

              if ('__all__' in error) {
                return AviBackendErrorMsgPipe.aviBackendErrorMsg(error.__all__);
              }

              // GET request 401 response code
              if ('detail' in error) {
                return AviBackendErrorMsgPipe.aviBackendErrorMsg(error.detail);
              }

              // Stringify and show if error is object/array.
              res = JSON.stringify(error);
            } catch (e) {
              console.warn('Backend error object %O has an unexpected format', e);
            }
          }

          break;
        }
      }
    }

    return res;
  }
}
