<avi-confirmation-dialog
  class="clr-wrapper"
  (onClose)="handleCancel()"
  headerText="{{ header }}"
>
  <ng-container ngProjectAs="avi-confirmation-dialog_modal-content">
    <div *ngIf="warning">{{ warning }}</div>
    <strong>{{ l10nKeys.continueQuestionWarning | l10nmessage : AviDialogResourceBundles }}</strong>
  </ng-container>

  <ng-container ngProjectAs="avi-confirmation-dialog_modal-actions">
    <button
      class="btn btn-outline"
      (click)="handleCancel()"
    >
      {{ l10nKeys.cancelBtnLabel | l10nmessage : AviDialogResourceBundles }}
    </button>

    <button
      (click)="handleContinue()"
      class="btn btn-danger"
    >
      {{ l10nKeys.yesContinueBtnLabel | l10nmessage : AviDialogResourceBundles }}
    </button>
  </ng-container>
</avi-confirmation-dialog>
