<div class="avi-usage-bar">
  <div 
    *ngIf="isHeaderRequired" 
    class="avi-usage-bar__header">
    <label 
      class="avi-usage-bar__unit-label-font avi-usage-bar__used-unit" 
      for="usage-bar"
    >
      {{ usedValue }}
      <span class="avi-usage-bar__unit-label">{{ usedUnitsLabel }}</span>
      <span 
        class="avi-usage-bar__unlicensed-label" 
        *ngIf="overConsumedUnits"
      >
        ({{ overConsumedUnits }} {{ l10nKeys.unlicensedLabel | l10nmessage : dictionary }} )
      </span>
    </label>
    <label 
      class="avi-usage-bar__unit-label-font avi-usage-bar__max-allowed-unit" 
      for="usage-bar"
    >
      {{ maxAllowedValueText }}
      <span class="avi-usage-bar__unit-label">{{ maxAllowedUnitsLabel }}</span>
  </label>
  </div>
  <avi-usage-bar-with-overflow
    [usedValue]="usedValue"
    [maxAllowedValue]="maxAllowedValue"
    [reservedValue]="reservedValue"
  ></avi-usage-bar-with-overflow>
  <div 
    class="avi-usage-bar__footer"
    *ngIf="isFooterRequired"
  >
    <label 
      class="avi-usage-bar__unit-label-font avi-usage-bar__reserved-unit"
      for="usage-bar"
    >
      {{ reservedValue }}
      <span class="avi-usage-bar__unit-label">
        {{ reservedUnitsLabel }}
      </span>
    </label>
  </div>
</div>
