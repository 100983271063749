<div class="avi-dashboard-widget">
  <avi-card
    [noBodyPadding]="true"
    [useContentHeight]="true"
  >
    <avi-card_header>
      <ng-container *ngIf="showCustomHeader; then customHeader; else dashboardWidgetHeader"></ng-container>
    </avi-card_header>
    <avi-card_body class="avi-dashboard-widget__avi-card-body">
      <avi-dashboard-widget-progress-bar
        [hidden]="!loading"
        [loading]="loading"
      ></avi-dashboard-widget-progress-bar>
      <ng-container *ngIf="showLoadingMessage; then loadingWidget; else content"></ng-container>
    </avi-card_body>
  </avi-card>
</div>

<ng-template #customHeader>
  <ng-content select="[dashboardWidgetHeader]"></ng-content>
</ng-template>

<ng-template #dashboardWidgetHeader>
  <cds-icon
    shape="{{ shape }}"
    size="{{ iconSize }}"
    class="avi-dashboard-widget__icon"
  ></cds-icon>
  <span class="avi-dashboard-widget__header-text">
    {{ headerText }}
  </span>
</ng-template>

<ng-template #content>
  <ng-content select="[dashboardWidgetBody]"></ng-content>
</ng-template>

<ng-template #loadingWidget>
  <div class="avi-dashboard-widget__loading-widget">
    {{ loadingMessage }}
  </div>
</ng-template>
