/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClrSpinnerModule } from '@clr/angular';
import { AviCardComponent } from './avi-card.component';

@NgModule({
  declarations: [
    AviCardComponent,
  ],
  exports: [
    AviCardComponent,
  ],
  imports: [
    CommonModule,
    ClrSpinnerModule,
  ],
})
export class AviCardModule {}
