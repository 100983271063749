/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DashboardModule
 */

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { IWidgetConfig, IAviComponentBindingInfo } from '../avi-dashboard.types';
import Masonry from 'masonry-layout';

const WIDGET_ITEM_SELECTOR = 'avi-dashboard-widgets__widget';
const WIDGET_GRID_SELECTOR = '.avi-dashboard-widgets';
const COLUMN_WIDTH = 265;
const GUTTER_WIDTH = 24;

/**
 * @description
 * Component that takes in a list of widgets and arranges them in a masonry layout. This is meant to
 * be a dumb component that takes a list of widget configurations and renders them all as is,
 * without doing any kind of filtering.
 * @author alextsg, Shanmukha Sarath Kondiparthi
 */
@Component({
  selector: 'avi-dashboard-widgets',
  templateUrl: './avi-dashboard-widgets.component.html',
})
export class AviDashboardWidgetsComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * List of IWidgetConfigs for this component to render.
   */
  @Input()
  public widgetConfigs: IWidgetConfig[] = [];

  /**
   * Optional masonary options for customization.
   */
  @Input()
  public masonryOptions: Masonry.Options = {};

  /**
   * Event emitter to trigger components binder.
   */
  @Output()
  public attachComponentBindingsEmitter = new EventEmitter<IAviComponentBindingInfo>();

  /**
   * Reference to the view container where each of the widgets will be rendered.
   */
  @ViewChild('widgetsContainer', {
    read: ViewContainerRef,
    static: true,
  })
  private widgetsContainerRef: ViewContainerRef;

  /**
   * Reference to the masonry instance, which is needed to redraw the layout after any widget size
   * changes and to destroy the instance in ngOnDestroy.
   */
  private masonry: Masonry;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  /**
   * @override
   * Create an instance of Masonry to set the widget layout.
   */
  public ngOnInit(): void {
    const mygrid = this.elementRef.nativeElement.querySelector(WIDGET_GRID_SELECTOR);

    this.masonry = new Masonry(mygrid, {
      itemSelector: `.${WIDGET_ITEM_SELECTOR}`,
      columnWidth: COLUMN_WIDTH,
      gutter: GUTTER_WIDTH,
      initLayout: false,
      ...this.masonryOptions,
    });
  }

  /**
   * @override
   * After all the widgets have been rendered within the viewContainer, draw the masonry layout.
   */
  public ngAfterViewInit(): void {
    this.renderWidgets();
    this.masonry.reloadItems?.();
    this.redrawLayout();
  }

  /**
   * @override
   * Destroy the masonry instance to remove any event listeners.
   */
  public ngOnDestroy(): void {
    this.masonry.destroy?.();
  }

  /**
   * Render each widget in the containerRef while also adding classes for the masonry selector
   * (used by masonry to place the widget) and for the width.
   */
  private renderWidgets(): void {
    this.widgetConfigs.forEach(({ widget, widgetBindings, width = 1 }) => {
      const componentRef = this.createComponentInstance(widget, widgetBindings);
      const { location, hostView } = componentRef;

      // Adds class to rendered widget, used by masonry-layout as an item identifier.
      this.renderer.addClass(location.nativeElement, WIDGET_ITEM_SELECTOR);

      this.renderer.setStyle(location.nativeElement, 'display', 'inline-block');
      this.renderer.setStyle(location.nativeElement, 'width', this.getWidgetWidth(width));

      this.widgetsContainerRef.insert(hostView);
    });
  }

  /**
   * Create the component instance and attach component bindings. redrawLayout is added as an
   * EventEmitter which can be called by widget components to redraw the layout once initial load
   * is complete.
   */
  private createComponentInstance(
    widget: Type<Component>,
    widgetBindings: Record<string, unknown> = {},
  ): ComponentRef<Component> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widget);
    const componentRef = componentFactory.create(this.widgetsContainerRef.injector);

    const componentBindings = {
      ...widgetBindings,
      redrawLayout: this.redrawLayout,
    };

    this.attachComponentBindingsEmitter.emit({
      componentRef,
      componentBindings,
    });

    return componentRef;
  }

  /**
   * Redraw the masonry layout.
   */
  private readonly redrawLayout = (): void => {
    setTimeout(() => this.masonry.layout?.());
  };

  /**
   * The width of a widget should be the width of x columns plus x - 1 gutters. Ex. if we have
   * a size 2 widget, it should take up 2 column widths + 1 gutter width.
   */
  private getWidgetWidth(width: number): string {
    const widgetWidth = COLUMN_WIDTH * width + GUTTER_WIDTH * (width - 1);

    return `${widgetWidth}px`;
  }
}
