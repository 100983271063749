<div class="avi-label-with-tooltip">
  <span class="avi-label-with-tooltip__label">
    <ng-content></ng-content>
  </span>
  <div
    class="avi-label-with-tooltip__required"
    *ngIf="required"
  ></div>
  <!-- TODO: AV-183910 - Need to implement all tooltip components into nsx-albn-ui. -->
  <!-- <helper-text-tooltip-icon
    class="avi-label-with-tooltip__icon"
    tooltipText="{{ tooltipText }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    enumType="{{ enumType }}"
    enumValue="{{ enumValue }}"
    [hideRange]="hideRange"
    rangeText="{{ rangeText }}"
  ></helper-text-tooltip-icon> -->
</div>
