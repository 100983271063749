/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing progress bar with overflow related components
 * @preferred
 * @module AviUsageBarModule
 */

import { 
  NgModule, 
  CUSTOM_ELEMENTS_SCHEMA 
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { ClrSpinnerModule } from '@clr/angular';
import { ClrProgressBarModule } from '@clr/angular';
import { AviUsageBarComponent } from './components/avi-usage-bar.component';
import { AviTooltipModule } from '../avi-tooltip';
import { AviCardModule } from '../avi-card'
import { AviPipesModule } from '../avi-pipes';
import { AviUsageBarWithOverflowComponent } from './components/avi-usage-bar-with-overflow/avi-usage-bar-with-overflow.component';

@NgModule({
  declarations: [
    AviUsageBarComponent,
    AviUsageBarWithOverflowComponent,
  ],
  exports: [
    AviUsageBarComponent,
    AviUsageBarWithOverflowComponent,
  ],
  imports: [
    CommonModule,
    ClrSpinnerModule,
    ClrProgressBarModule,
    AviTooltipModule,
    AviCardModule,
    AviPipesModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AviUsageBarModule {}
