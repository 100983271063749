/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const packageName = 'nsx-alb-ui';
const moduleName = 'avi-usage-bar';
const prefix = `${packageName}.${moduleName}`;

export const usedLabel = `${prefix}.usedLabel`;
export const maxLabel = `${prefix}.maxLabel`;
export const unlicensedLabel = `${prefix}.unlicensedLabel`;
export const reservedLabel = `${prefix}.reservedLabel`;

export const AviUsageBarResourceBundles = Object.seal({
  [usedLabel]: 'Used',
  [maxLabel]: 'Max',
  [unlicensedLabel]: 'Unlicensed',
  [reservedLabel]: 'Reserved',
});
