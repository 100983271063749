/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviDashboardModule
 */

import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

/**
 * @description
 * Generic Dashboard widget component. Other widgets should be built on top of this component.
 *
 * The header can be a simple headerText and icon (shape), but for more complicated headers (like
 * those with a call-to-action) the header can be transcluded.
 *
 * The widget can render a progress bar under the header based on the loading binding. On initial
 * load, a loading widget message can be displayed with the showLoadingMessage binding.
 *
 * The widget body is transcluded with the attribute dashboardWidgetBody. The optional custom header
 * is transcluded with the attribute dashboardWidgetHeader.
 *
 * @author alextsg
 */
@Component({
  selector: 'avi-dashboard-widget',
  templateUrl: './avi-dashboard-widget.component.html',
  styleUrls: [
    './avi-dashboard-widget.component.less',
  ],
})
export class AviDashboardWidgetComponent implements OnInit {
  /**
   * If true, displays a progress bar under the header.
   */
  @Input()
  public loading = false;

  /**
   * Icon used in the header. If nothing is passed in, the header will only show text. If no text
   * is passed in, then this component renders a header via content projection.
   */
  @Input()
  public shape?: string;

  /**
   * Size of the icon in the header.
   */
  @Input()
  public iconSize = 24;

  /**
   * String to be rendered as the header title. If neither a shape or headerText are passed in,
   * then this component renders a header via content projection.
   */
  @Input()
  public headerText?: string;

  /**
   * If true, shows the "Loading Widget" message. This is generally only applicable on an initial
   * load - on subsequent polling loads, widgets should probably show stale data with the progress
   * bar and not the "Loading Widget" message.
   */
  @Input()
  public showLoadingMessage = false;

  /**
   * Loading message text for widget component.
   */
  @Input()
  public loadingMessage = 'Loading Widget';

  /**
   * If true, renders a header via content projection.
   */
  public showCustomHeader = false;

  /** @override */
  public ngOnInit(): void {
    this.showCustomHeader = !this.shape && !this.headerText;
  }
}
