/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviDialogModule */

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

/**
* @description
*      Confirmation modal component.
*      content and buttons are to be transcluded.
*
* @author Alex Tseung, Zhiqian Liu, Aravindh Nagarajan, Kondiparthi Shanmukha Sarath
*/
@Component({
  selector: 'avi-confirmation-dialog',
  templateUrl: './avi-confirmation-dialog.component.html',
  styleUrls: [ './avi-confirmation-dialog.component.less' ],
})
export class AviConfirmationDialogComponent implements OnInit {
  /**
   * Title for the confirmation popup.
   */
  @Input()
  public headerText = '';

  /**
   * Size of the confirmation modal.
   */
  @Input()
  public size = '';

  /**
   * If true, emits the onClose event when the backdrop is clicked.
   */
  @Input()
  public closeOnBackdropClick = false;

  /**
   * If true, emits the onClose event when the escape key is pressed.
   */
  @Input()
  public closeOnEscapeKeypress = true;

  /**
   * Function to be called when the modal-close button is clicked.
   */
  @Output()
  public onClose = new EventEmitter();

  /**
   * Flag to allow dialog dismissal.
   */
  public allowDialogDismissal = false;

  /**
   * Handle on dialog closed event.
   */
  public onDialogStatusChange(isDialogOpen: boolean): void {
    if (!isDialogOpen) {
      this.onClose.emit();
    }
  }

  /**
   * @override
   * Allow dialog dismissal after a delay to avoid create/dismiss loop when used with full modal.
   */
  ngOnInit(): void {
    setTimeout(() => {
      this.allowDialogDismissal = true;
    }, 150);
  }

  /**
   * Listener for escape keydown event.
   * from bubbling to parent full-modal-config component.
   */
  @HostListener('keydown.esc', ['$event'])
  private onEscKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}
