/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClrModalModule } from '@clr/angular';
import { AviPipesModule } from '../avi-pipes';

import { AviDialogService } from './services/avi-dialog.service';
import { AviConfirmationDialogComponent } from './components/avi-confirmation-dialog';
import { AviContinueConfirmationDialogComponent } from './components/avi-continue-confirmation-dialog';
import { AviDismissChangesConfirmationDialogComponent } from './components/avi-dismiss-changes-confirmation-dialog';

@NgModule({
  declarations: [
    AviConfirmationDialogComponent,
    AviContinueConfirmationDialogComponent,
    AviDismissChangesConfirmationDialogComponent,
  ],
  exports: [
    AviConfirmationDialogComponent,
    AviContinueConfirmationDialogComponent,
    AviDismissChangesConfirmationDialogComponent,
  ],
  imports: [
    AviPipesModule,
    ClrModalModule,
    CommonModule,
  ],
  providers: [
    AviDialogService,
  ],
})
export class AviDialogModule {}
