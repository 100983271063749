/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviPipesModule
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'l10nmessage',
})
export class AviL10nMsgPipe implements PipeTransform {
  transform(value: string, resourceBundle: Record<string, string>): string {
    return resourceBundle[value];
  }
}
