/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing dataGrid related components
 * @preferred
 * @module AviDataGridModule
 */

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  ClrCheckboxModule,
  ClrDatagridModule,
  ClrDropdownModule,
} from '@clr/angular';

import {
  AviDataGridBaseComponent,
  AviDataGridColumnSelectionComponent,
} from './avi-data-grid-base';

import { AviDataGridComponent } from './avi-data-grid.component';
import { AviTooltipModule } from '../avi-tooltip/avi-tooltip.module';

@NgModule({
  declarations: [
    AviDataGridColumnSelectionComponent,
    AviDataGridBaseComponent,
    AviDataGridComponent,
  ],
  exports: [
    AviDataGridColumnSelectionComponent,
    AviDataGridBaseComponent,
    AviDataGridComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    AviTooltipModule,
    ClrDropdownModule,
    ClrDatagridModule,
    ClrCheckboxModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class AviDataGridModule { }
