/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const packageName = 'nsx-alb-ui';
const moduleName = 'avi-dialog';
const prefix = `${packageName}.${moduleName}`;

export const warningHeader = `${prefix}.warningHeader`;
export const continueQuestionWarning = `${prefix}.continueQuestionWarning`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const yesContinueBtnLabel = `${prefix}.yesContinueBtnLabel`;
export const resetToDefaultsHeader = `${prefix}.resetToDefaultsHeader`;
export const unsavedChangesHeader = `${prefix}.unsavedChangesHeader`;
export const unsavedChangesWarning = `${prefix}.unsavedChangesWarning`;
export const resetToDefaultsWarning = `${prefix}.resetToDefaultsWarning`;

export const AviDialogResourceBundles = Object.seal({
  [warningHeader]: 'Warning',
  [continueQuestionWarning]: 'Are you sure you want to continue?',
  [cancelBtnLabel]: 'Cancel',
  [yesContinueBtnLabel]: 'Yes, Continue',
  [resetToDefaultsHeader]: 'Reset to Defaults',
  [unsavedChangesHeader]: 'Unsaved Changes',
  [unsavedChangesWarning]: 'Changes have not been saved.',
  [resetToDefaultsWarning]: 'Switching type will reset any stored values to default.',
});
