/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviTooltipModule */

import {
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import {
  Observable,
  Subscription,
} from 'rxjs';

import {
  ConnectedOverlayPositionChange,
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';

import {
  Cancelable,
  debounce,
} from 'lodash';

import { TemplatePortal } from '@angular/cdk/portal';
import { defaultPositionsPriority } from './avi-tooltip.constants';

const ESCAPE_KEY_CODE = 27;

/**
 * @ngdoc directive
 * @name AviTooltipDirective
 * @description Directive for displaying tooltip content over an origin element.
 * @author alextsg
 */
@Directive({
  selector: '[avi-tooltip]',
})
export class AviTooltipDirective implements OnInit, OnDestroy, OnChanges {
  /**
   * List of positions to prioritize.
   */
  @Input()
  public positionsPriority = defaultPositionsPriority;

  /**
   * True if the tooltip content should show when the origin element is clicked.
   */
  @Input()
  public showOnClick = false;

  /**
   * True if the tooltip should open only when dictated by tooltipControl$.
   * Ignores onHover and onClick events.
   */
  @Input()
  public showOnControlOnly = false;

  /**
   * Amount of time in milliseconds before the tooltip content is rendered.
   */
  @Input()
  public delay = 0;

  /**
   * ClassName added to the backdrop element.
   */
  @Input()
  public backdropClass = '';

  /**
   * Used for the parent to dictate if the tooltip content should be opened or closed. Observable
   * to be subscribed to, returns true to open and false to close.
   */
  @Input()
  public tooltipControl$ = new Observable<boolean>();

  /**
   * Used for updating the overlay position. It seems like Angular CDK doesn't know when the
   * overlay size has changed after opening (like if we make an HTTP request when the overlay is
   * open and the size grows after we load information), and the position needs to be updated
   * after the size changes.
   */
  @Input()
  public overlaySizeChange$ = new Observable<void>();

  /**
   * True to prevent rendering the tooltip.
   */
  @Input()
  public hideTooltip = false;

  /**
   * Max width of the overlay. Used, for example, to limit the width of dropdown options.
   */
  @Input()
  public overlayMaxWidth?: number;

  /**
   * Don't let overlay detach itself on mouseleave event, when showOnControlOnly is false.
   */
  @Input()
  public hideOnMouseLeave = true;

  /**
   * Event emitted when the tooltip has been toggled.
   */
  @Output()
  public openedChange = new EventEmitter<boolean>();

  /**
   * Called when the tooltip render position has changed.
   */
  @Output()
  public onPositionChange = new EventEmitter<ConnectedPosition>();

  /**
   * Called when the backdrop of the tooltip is clicked.
   */
  @Output()
  public onBackdropClick = new EventEmitter<void>();

  /**
   * Transcluded tooltip content.
   */
  @ContentChild('aviTooltipContent')
  private tooltipContent: TemplateRef<HTMLElement>;

  /**
   * Debounced call of this.attach which attaches the tooltip content.
   */
  private debouncedAttach: (() => void) & Cancelable;

  /**
   * Flag to track of the tooltip content is attached.
   */
  private isAttached = false;

  /**
   * TemplatePortal instance. Gets attached to the overlayRef.
   */
  private templatePortal: TemplatePortal<HTMLElement>;

  /**
   * overlayRef instance from Overlay.create.
   */
  private overlayRef: OverlayRef;

  /**
   * Subscription to the backdrop click event.
   */
  private backdropSubscription: Subscription;

  /**
   * Subscription to the overlay position change event.
   */
  private overlayPositionSubscription: Subscription;

  /**
   * Subscription to the tooltip control event.
   */
  private tooltipControlSubscription: Subscription;

  /**
   * Subscription for overlay size changes.
   */
  private overlaySizeChangeSubscription: Subscription;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private zone: NgZone,
  ) {
    this.debouncedAttach = debounce(this.attach, this.delay);
  }

  /**
   * Attach the tooltip when clicked if this.showOnClick is true.
   */
  @HostListener('click')
  public handleClick(): void {
    if (this.showOnClick) {
      if (this.isAttached) {
        this.detach();
      } else {
        this.debouncedAttach();
      }
    }
  }

  /**
   * Attach the tooltip on mouse enter for onHover tooltips.
   */
  @HostListener('mouseenter')
  public show(): void {
    if (!this.showOnClick && !this.showOnControlOnly) {
      this.debouncedAttach();
    }
  }

  /**
   * Handle escape key press event and detach the tooltip.
   */
  @HostListener('document:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (event.which === ESCAPE_KEY_CODE) {
      this.detach();
    }
  }

  /**
   * Detach the tooltip on mouse leave for onHover tooltips.
   */
  @HostListener('mouseleave')
  public hide(): void {
    if (!this.showOnClick && !this.showOnControlOnly && this.hideOnMouseLeave) {
      this.detach();
    }
  }

  /**
   * @override
   */
  public ngOnInit(): void {
    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.positionsPriority)
      .withGrowAfterOpen(true);

    this.overlayRef = this.overlay.create({
      backdropClass: this.backdropClass,
      hasBackdrop: this.showOnClick || this.showOnControlOnly,
      maxWidth: this.overlayMaxWidth,
      positionStrategy,
    });

    this.backdropSubscription = this.overlayRef.backdropClick()
      .subscribe(this.handleBackdropClick);

    this.overlayPositionSubscription = positionStrategy.positionChanges
      .subscribe(this.handlePositionChange);

    this.tooltipControlSubscription = this.tooltipControl$
      .subscribe(this.handleTooltipControlChange);

    this.overlaySizeChangeSubscription = this.overlaySizeChange$
      .subscribe(this.handleOverlaySizeChange);
  }

  /**
   * @override
   * Used to detect changes to the overlay maxWidth for resizing.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.overlayRef && changes.overlayMaxWidth) {
      const { overlayMaxWidth } = changes;
      const { currentValue } = overlayMaxWidth;

      this.overlayRef.updateSize({ maxWidth: currentValue });
    }
  }

  /**
   * @override
   */
  public ngOnDestroy(): void {
    this.backdropSubscription.unsubscribe();
    this.overlayPositionSubscription.unsubscribe();
    this.tooltipControlSubscription.unsubscribe();
    this.overlaySizeChangeSubscription.unsubscribe();

    this.debouncedAttach.cancel();
    this.overlayRef.dispose();
  }

  /**
   * @override
   */
  public ngAfterContentInit(): void {
    this.templatePortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
  }

  /**
   * Attaches the tooltip.
   */
  private attach = (): void => {
    if (this.hideTooltip) {
      return;
    }

    if (!this.isAttached) {
      this.overlayRef.attach(this.templatePortal);
      this.isAttached = true;
      this.openedChange.emit(this.isAttached);
    }
  };

  /**
   * Detaches the tooltip.
   */
  private detach(): void {
    this.debouncedAttach.cancel();

    if (this.isAttached) {
      this.overlayRef.detach();
      this.isAttached = false;
      this.openedChange.emit(this.isAttached);
    }
  }

  /**
   * Handler for a position change. If a change happens, emits the onPositionChange EventEmitter.
   */
  private handlePositionChange = (positionChange: ConnectedOverlayPositionChange): void => {
    this.zone.run(() => this.onPositionChange.emit(positionChange.connectionPair));
  };

  /**
   * Handler for clicking the backdrop, which appears when this.showOnClick is true, so that
   * clicking the backdrop hides the popup.
   */
  private handleBackdropClick = (): void => {
    if (this.showOnClick) {
      this.detach();
    }

    this.onBackdropClick.emit();
  };

  /**
   * Handler for tooltipControl changes, which is handled by the parent.
   */
  private handleTooltipControlChange = (showTooltip: boolean): void => {
    if (showTooltip) {
      this.debouncedAttach();
    } else {
      this.detach();
    }
  };

  /**
   * Handler for overlay size changes. Updates the position if the position is no longer valid
   * after the size change.
   */
  private handleOverlaySizeChange = (): void => {
    if (this.overlayRef && this.isAttached) {
      setTimeout(() => this.overlayRef.updatePosition());
    }
  };
}
